import { getCurrencyForSite, getLanguageFromLocale, gottenConfig } from "@config/site/site-config";

import {
    PaymentState,
    type LineItem,
    type Money,
    type Order,
    type Product,
    type ProductVariant,
} from "@graphql/generated/components";
import type {
    AlgoliaItem,
    ListData,
    PossibleEventItem,
    AnalyticDefaultItem,
    AnalyticsExtendedItem,
} from "@lib/analytics/analyticsTypes";
import { EventTypes, AlgoliaEventMethods, AnalyticsListTypes } from "@lib/analytics/analyticsTypes";
import { getImperialSizeForProduct } from "@lib/analytics/getImperialSizeForProduct";
import { sha256 } from "@lib/analytics/sha256";
import { Attributes, CustomAttributes } from "@lib/enums/ProductAttributes";
import { getProductAttributeValue } from "@lib/utils/attributesUtils";
import { getMoneyFromCentsAndFractions } from "@lib/utils/moneyUtils";
import type { Me } from "@store/index";
import type { Hit } from "instantsearch.js";
import { isEqual } from "lodash-es";
import type { Session } from "next-auth";
import aa from "search-insights";
import { isAnalyticsCookiesAllowed } from "@lib/analytics/isAnalyticsCookiesAllowed";
import { getAlgoliaUserToken } from "@lib/analytics/getAlgoliaUserToken";
import { LogTag, Logger, ServiceType } from "@lib/monitoring/logger";
import { getAlgoliaIndexName } from "@lib/wrappers/algolia";

class AnalyticsClient {
    locale: string;
    session: Session;
    me: Me;
    algoliaIndexName: string = "";
    lastSavedEvents: Map<EventTypes | AlgoliaEventMethods, any> = new Map();
    selectedItems: Map<
        string,
        ListData & {
            lastAppliedFilter: { filterName: string; filterCategory: string };
            lastAppliedSorting?: { sorting: string };
        }
    > = new Map();
    lastAppliedSorting: { sorting: string };
    lastAppliedFilter: { filterName: string; filterCategory: string; facetName: string };
    lastAppliedSearch: string;
    lastSelectedPromotion: {
        promotionName: string;
        promotionId: string;
    } = {
        promotionName: undefined,
        promotionId: undefined,
    };
    currencyCode: string;

    saveEvent(
        event: { event: EventTypes | AlgoliaEventMethods; [key: string]: any },
        shouldCheckIfSaved = true
    ) {
        if (shouldCheckIfSaved && this.isAlreadyPushed(event.event, event)) {
            return;
        }
        this.savePushedEvent(event.event, event);
        window.dataLayer && window.dataLayer.push(event);
    }

    saveEcommerceEvent(event: { event: EventTypes | AlgoliaEventMethods; [key: string]: any }) {
        if (this.isAlreadyPushed(event.event, event)) {
            return;
        }

        this.savePushedEvent(event.event, event);
        window.dataLayer &&
            window.dataLayer.push({ ecommerce: null }) &&
            window.dataLayer.push(event);
    }

    getMoneyFromItem = (item: PossibleEventItem) => {
        const isProduct = (it: PossibleEventItem): it is Product => {
            return (it as ProductVariant).price === undefined;
        };

        if (!isProduct(item)) {
            return getMoneyFromCentsAndFractions(
                item.price?.value?.centAmount,
                item.price?.value?.fractionDigits
            );
        } else {
            return getMoneyFromCentsAndFractions(
                item.masterData?.current?.variants.at(-1)?.price?.value?.centAmount ?? 0,
                item.masterData?.current?.variants.at(-1)?.price?.value?.fractionDigits ?? 0
            );
        }
    };

    getCartValue = (items) => {
        return items.reduce((acc, item) => acc + this.getMoneyFromItem(item) * item.quantity, 0);
    };

    mapLineItemToEventItem = (item: LineItem, index?: number): AnalyticsExtendedItem => {
        const params = new URLSearchParams(window.location.href);
        const savedListingData = this.selectedItems.get(item.key);

        const lineItemPrice: Money = getProductAttributeValue(
            item.custom.customFieldsRaw,
            CustomAttributes.lineItemPrice
        );
        const lineItemDiscounted: Money = getProductAttributeValue(
            item.custom.customFieldsRaw,
            CustomAttributes.lineItemDiscountedPrice
        );
        const includedDiscounts =
            item.discountedPricePerQuantity[0]?.discountedPrice?.includedDiscounts ?? [];
        const discountkey =
            includedDiscounts.length > 0 ? (includedDiscounts[0]?.discount?.key ?? "") : "";

        return {
            item_id: item.id,
            item_variant: item.productKey,
            item_name: item.name,
            promotion_name: this.lastSelectedPromotion.promotionName,
            promotion_id: this.lastSelectedPromotion.promotionId,
            item_list_name: savedListingData?.listName,
            item_list_id: savedListingData?.listId,
            item_category: getProductAttributeValue(
                item.variant?.attributesRaw,
                Attributes.division,
                this.locale
            ),
            item_category2: getProductAttributeValue(
                item.variant?.attributesRaw,
                Attributes.collectionType,
                this.locale
            ),
            item_category3: getProductAttributeValue(
                item.variant?.attributesRaw,
                Attributes.collectionClass,
                this.locale
            ),
            item_brand: "ECCO",
            currency: this.currencyCode,
            price: getMoneyFromCentsAndFractions(
                item.totalPrice?.centAmount ?? 0,
                item.totalPrice?.fractionDigits ?? 0
            ),
            quantity: item.quantity,
            index: index ?? savedListingData?.position ?? 0,
            discount:
                lineItemDiscounted?.centAmount && lineItemPrice?.centAmount
                    ? getMoneyFromCentsAndFractions(
                          lineItemPrice.centAmount - lineItemDiscounted.centAmount,
                          lineItemPrice.fractionDigits
                      )
                    : 0,
            coupon: discountkey,
            list_sorting: savedListingData?.lastAppliedSorting?.sorting,
            list_filter_category: savedListingData?.lastAppliedFilter?.filterCategory,
            list_filter_name: savedListingData?.lastAppliedFilter?.filterName,
            list_search_key: this.lastAppliedSearch,
            colour: getProductAttributeValue(
                item.variant?.attributesRaw,
                Attributes.dominantColor,
                this.locale
            ),
            variant_price: this.getMoneyFromItem(item),
            full_price: lineItemPrice
                ? getMoneyFromCentsAndFractions(
                      lineItemPrice.centAmount,
                      lineItemPrice.fractionDigits
                  )
                : 0,
            default_variant: item.productKey,
            size: getProductAttributeValue(item.variant?.attributesRaw, Attributes.size),
            size_imperial: getImperialSizeForProduct(item),
            size_type: params.get("system") === "us" ? "Imperial" : "Metric",
            barcode: item.variant?.sku,
            stock_count_online: item.variant?.availability?.channels?.total ?? 0,
            stock_count_offline: undefined,
            on_sale: undefined,
            label_name: undefined,
            label_source: undefined,
            source: undefined,
        };
    };

    saveSearch = (searchTerm) => {
        this.lastAppliedSearch = searchTerm;
    };

    mapCommercetoolsItemToEventItem = (
        item: Product & { variant?: Partial<ProductVariant> }
    ): AnalyticDefaultItem | AnalyticsExtendedItem => {
        const savedListingData = this.selectedItems.get(item.key);
        const params = new URLSearchParams(window.location.href);

        return {
            item_id: item.id,
            item_variant: item.key,
            item_name: item.masterData?.current?.name,
            promotion_name: this.lastSelectedPromotion.promotionName,
            promotion_id: this.lastSelectedPromotion.promotionId,
            colour: getProductAttributeValue(
                item.masterData?.current?.masterVariant?.attributesRaw,
                Attributes.dominantColor,
                this.locale
            ),
            item_list_name: savedListingData?.listName,
            item_list_id: savedListingData?.listId,
            item_category: getProductAttributeValue(
                item.masterData?.current?.masterVariant?.attributesRaw,
                Attributes.division,
                this.locale
            ),
            item_category2: getProductAttributeValue(
                item.masterData?.current?.masterVariant?.attributesRaw,
                Attributes.collectionType,
                this.locale
            ),
            item_category3: getProductAttributeValue(
                item.masterData?.current?.masterVariant?.attributesRaw,
                Attributes.collectionClass,
                this.locale
            ),
            item_brand: "ECCO",
            currency: this.currencyCode,
            price: this.getMoneyFromItem(item),
            quantity: 1,
            index: savedListingData?.position ?? 0,
            discount: 0,
            coupon: "",
            default_variant: item.key,
            list_sorting: savedListingData?.lastAppliedSorting?.sorting,
            list_filter_category: savedListingData?.lastAppliedFilter?.filterCategory,
            list_filter_name: savedListingData?.lastAppliedFilter?.filterName,
            list_search_key: this.lastAppliedSearch,
            size: getProductAttributeValue(item?.variant?.attributesRaw, Attributes.size) ?? "",
            size_imperial: getImperialSizeForProduct(item),
            size_type: params.get("system") === "us" ? "Imperial" : "Metric",
            barcode: item.variant?.sku,
        };
    };

    mapAlgoliaItemToEventItem = (
        item: Hit<AlgoliaItem>,
        listData?: ListData
    ): AnalyticDefaultItem => {
        const { listName = "", listId = "loaded", position = 0 } = listData;

        return {
            item_id: item.objectID,
            item_variant: item.articleNumber,
            item_name: item.name,
            promotion_name: this.lastSelectedPromotion.promotionName,
            promotion_id: this.lastSelectedPromotion.promotionId,
            item_list_name: listName,
            item_list_id: listId,
            item_category: item.division ?? "",
            item_category2: item.collectionType ?? "",
            item_category3: item.collectionClass,
            item_brand: "ECCO",
            currency: this.currencyCode,
            price: item.price,
            quantity: 1,
            index: position,
            discount: item.hasDiscount ? item.discountPercentage : 0,
            coupon: "",
            list_sorting: this.lastAppliedSorting?.sorting,
            list_filter_category: this.lastAppliedFilter?.filterCategory,
            list_filter_name: this.lastAppliedFilter?.filterName,
            list_search_key: this.lastAppliedSearch,
            barcode: item.objectID,
        };
    };

    init(locale: string, me: Me, session: Session) {
        this.me = me;
        this.locale = locale;
        this.currencyCode = getCurrencyForSite;
        this.session = session;
        this.algoliaIndexName = getAlgoliaIndexName(locale);
        //@ts-ignore
        window.analyticsClient = this;

        aa(AlgoliaEventMethods.init, {
            appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
            apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
            useCookie: !!me?.customer?.id || isAnalyticsCookiesAllowed(),
            authenticatedUserToken: me?.customer?.id,
            userToken: getAlgoliaUserToken(me),
        });
    }

    savePushedEvent(eventType: EventTypes | AlgoliaEventMethods, item: any) {
        const savedData = this.lastSavedEvents.get(eventType) ?? [];
        this.lastSavedEvents.set(eventType, [...savedData, item]);
    }

    isAlreadyPushed(eventType: EventTypes | AlgoliaEventMethods, item: any) {
        const lastSavedEvents = this.lastSavedEvents.get(eventType) ?? [];
        return lastSavedEvents.find((it) =>
            isEqual(
                { ...item, "gtm.uniqueEventId": undefined },
                {
                    ...it,
                    "gtm.uniqueEventId": undefined,
                }
            )
        );
    }

    pageView = (url, pageType) => {
        Logger.info(ServiceType.WEB, "AnalyticsClient.pageView", {
            tag: LogTag.ANALYTICS,
            url,
            pageType,
        });
        this.saveEvent({
            event: EventTypes.pageView,
            new_url: url,
            previous_url: document.referrer,
            content_group: pageType,
        });
    };

    updateAnalyticsClientSettings(me: Me, session: Session) {
        this.me = me;
        this.session = session;
    }

    viewItem = async (item: Product, algoliaQueryId?: string) => {
        if (!item?.masterData) {
            return;
        }

        try {
            this.saveEcommerceEvent({
                event: EventTypes.viewItem,
                ecommerce: {
                    value: this.getMoneyFromItem(item),
                    event_id: await sha256(Math.random + Date.now().toString()),
                    items: [this.mapCommercetoolsItemToEventItem(item)],
                },
            });
            aa(
                algoliaQueryId
                    ? AlgoliaEventMethods.convertedObjectIDsAfterSearch
                    : AlgoliaEventMethods.convertedObjectIDs,
                {
                    index: this.algoliaIndexName,
                    eventName: algoliaQueryId
                        ? AlgoliaEventMethods.convertedObjectIDsAfterSearch
                        : AlgoliaEventMethods.convertedObjectIDs,
                    objectIDs: [item.key],
                    queryID: algoliaQueryId,
                }
            );
            algoliaQueryId &&
                this.lastAppliedFilter &&
                aa(AlgoliaEventMethods.convertedFilters, {
                    index: this.algoliaIndexName,
                    eventName: AlgoliaEventMethods.convertedFilters,
                    filters: [
                        `${this.lastAppliedFilter.filterName}:${this.lastAppliedFilter.filterCategory}`,
                    ],
                });
        } catch (e) {
            this.logError("AnalyticsClient.viewItem error", e);
        }
    };

    selectItem(item: AlgoliaItem & { position?: number }, listData: ListData, queryID?: string) {
        if (!item.objectID) {
            return;
        }
        try {
            const position = item.position ? item.position : listData.position + 1;
            this.selectedItems.set(item.objectID, {
                ...listData,
                listId: "clicked",
                position,
                lastAppliedFilter:
                    listData.listName === AnalyticsListTypes.plp
                        ? this.lastAppliedFilter
                        : undefined,
                lastAppliedSorting:
                    listData.listName === AnalyticsListTypes.plp
                        ? this.lastAppliedSorting
                        : undefined,
            });
            this.saveEcommerceEvent({
                event: EventTypes.selectItem,
                ecommerce: {
                    value: item.price,
                    items: [
                        this.mapAlgoliaItemToEventItem(
                            { ...item, __position: position, __queryID: queryID },
                            { ...listData, listId: "clicked" }
                        ),
                    ],
                },
            });

            aa(
                queryID
                    ? AlgoliaEventMethods.clickedObjectIDsAfterSearch
                    : AlgoliaEventMethods.clickedObjectIDs,
                {
                    index: this.algoliaIndexName,
                    eventName: queryID
                        ? AlgoliaEventMethods.clickedObjectIDsAfterSearch
                        : AlgoliaEventMethods.clickedObjectIDs,
                    objectIDs: [item.objectID],
                    positions: queryID ? [position] : undefined,
                    queryID: queryID,
                }
            );
        } catch (e) {
            this.logError("AnalyticsClient.selectItem error", e);
        }
    }

    loadItemsList(items: AlgoliaItem[], listData: ListData = {}) {
        if (!items?.length) {
            return;
        }
        try {
            const otherItems = [...items];
            while (otherItems.length) {
                const slicedItems = otherItems.splice(0, 15);
                this.saveEcommerceEvent({
                    event: EventTypes.loadItemsList,
                    ecommerce: {
                        value: slicedItems.reduce((acc, it) => acc + it.price, 0),
                        items: slicedItems.map((it: Hit<AlgoliaItem>, index) =>
                            this.mapAlgoliaItemToEventItem(it, {
                                ...listData,
                                listId: "loaded",
                                position: index,
                            })
                        ),
                    },
                });
            }
        } catch (e) {
            Logger.warn(ServiceType.WEB, "AnalyticsClient.loadItemsList error", {
                tag: LogTag.ANALYTICS,
                error: e,
            });
        }
    }

    viewItemsList(items: AlgoliaItem[], listData: ListData) {
        if (!items?.length) {
            return;
        }

        try {
            const otherItems = [...items];
            while (otherItems.length) {
                const slicedItems = otherItems.splice(0, 15);

                this.saveEcommerceEvent({
                    event: EventTypes.viewItemsList,
                    ecommerce: {
                        value: slicedItems.reduce((acc, it) => acc + it.price, 0),
                        items: slicedItems.map((it: Hit<AlgoliaItem>, index) =>
                            this.mapAlgoliaItemToEventItem(it, {
                                ...listData,
                                listId: "viewed",
                                position: index,
                                ...this.lastAppliedFilter,
                                ...this.lastAppliedSorting,
                            })
                        ),
                    },
                });

                aa(AlgoliaEventMethods.viewedObjectIDs, {
                    index: this.algoliaIndexName,
                    eventName: AlgoliaEventMethods.viewedObjectIDs,
                    objectIDs: items.map((it) => it.objectID),
                });
            }
        } catch (e) {
            this.logError("AnalyticsClient.viewItemsList error", e);
        }
    }

    addToCart = async (item: PossibleEventItem, algoliaQueryId?: string) => {
        if (!item) {
            return;
        }

        const isProduct = (it: PossibleEventItem): it is Product => {
            return (it as Product).masterData !== undefined;
        };

        try {
            this.saveEcommerceEvent({
                event: EventTypes.addToCart,
                ecommerce: {
                    value: this.getMoneyFromItem(item),
                    event_id: await sha256(
                        EventTypes.addToCart + Math.random + Date.now().toString()
                    ),
                    items: [
                        isProduct(item)
                            ? this.mapCommercetoolsItemToEventItem(item as Product)
                            : this.mapLineItemToEventItem(item as LineItem),
                    ],
                },
            });

            aa(
                algoliaQueryId
                    ? AlgoliaEventMethods.addedToCartObjectIDsAfterSearch
                    : AlgoliaEventMethods.addedToCartObjectIDs,
                {
                    index: this.algoliaIndexName,
                    objectIDs: [item.key],
                    eventName: algoliaQueryId
                        ? AlgoliaEventMethods.addedToCartObjectIDsAfterSearch
                        : AlgoliaEventMethods.addedToCartObjectIDs,
                    value: this.getMoneyFromItem(item),
                    currency: gottenConfig.currency,
                    queryID: algoliaQueryId,
                }
            );
        } catch (e) {
            this.logError("AnalyticsClient.addToCart error", e);
        }
    };

    addToCartFromShoppingBag = async (item: LineItem) => {
        if (!item) {
            return;
        }
        try {
            this.saveEcommerceEvent({
                event: EventTypes.addToCart,
                value: this.getMoneyFromItem(item),
                event_id: await sha256(EventTypes.addToCart + Math.random + Date.now().toString()),
                items: [this.mapLineItemToEventItem(item)],
            });
        } catch (e) {
            this.logError("AnalyticsClient.addToCart error", e);
        }
    };

    addToWishList = async (item: Hit<AlgoliaItem>, listData = {}) => {
        if (!item) {
            return;
        }
        try {
            this.saveEcommerceEvent({
                event: EventTypes.addToWishlist,
                ecommerce: {
                    value: item.price,
                    event_id: await sha256(
                        EventTypes.addToWishlist + Math.random + Date.now().toString()
                    ),
                    items: [
                        this.mapAlgoliaItemToEventItem(item, { ...listData, listId: "clicked" }),
                    ],
                },
            });
        } catch (e) {
            this.logError("AnalyticsClient.addToWishList error", e);
        }
    };

    removeFromWishList = async (item: Hit<AlgoliaItem>, listData = {}) => {
        if (!item) {
            return;
        }
        try {
            this.saveEcommerceEvent({
                event: EventTypes.removeFromWishlist,
                ecommerce: {
                    value: item.price,
                    event_id: await sha256(
                        EventTypes.removeFromWishlist + Math.random + Date.now().toString()
                    ),
                    items: [
                        this.mapAlgoliaItemToEventItem(item, { ...listData, listId: "clicked" }),
                    ],
                },
            });
        } catch (e) {
            this.logError("AnalyticsClient.removeFromWishList error", e);
        }
    };

    addCTProductToWishList = async (
        item: Product & { variant?: Partial<ProductVariant> },
        algoliaQueryId?: string
    ) => {
        if (!item) {
            return;
        }
        try {
            this.saveEcommerceEvent({
                event: EventTypes.addToWishlist,
                ecommerce: {
                    value: this.getMoneyFromItem(item),
                    event_id: await sha256(
                        EventTypes.addToWishlist + Math.random + Date.now().toString()
                    ),
                    items: [this.mapCommercetoolsItemToEventItem(item)],
                },
            });
            aa(
                algoliaQueryId
                    ? AlgoliaEventMethods.convertedObjectIDsAfterSearch
                    : AlgoliaEventMethods.convertedObjectIDs,
                {
                    index: this.algoliaIndexName,
                    eventName: algoliaQueryId
                        ? AlgoliaEventMethods.convertedObjectIDsAfterSearch
                        : AlgoliaEventMethods.convertedObjectIDs,
                    objectIDs: [item.key],
                    queryID: algoliaQueryId,
                }
            );
        } catch (e) {
            this.logError("AnalyticsClient.addCTProductToWishList error", e);
        }
    };

    removeCTProductFromWishlist = async (item: LineItem) => {
        if (!item) {
            return;
        }

        try {
            this.saveEcommerceEvent({
                event: EventTypes.removeFromWishlist,
                ecommerce: {
                    value: this.getMoneyFromItem(item),
                    event_id: await sha256(
                        EventTypes.removeFromWishlist + Math.random + Date.now().toString()
                    ),
                    items: [this.mapLineItemToEventItem(item)],
                },
            });
        } catch (e) {
            this.logError("AnalyticsClient.removeFromWishlist error", e);
        }
    };

    removeFromCart(item: LineItem, index) {
        if (!item) {
            return;
        }
        try {
            this.saveEcommerceEvent({
                event: EventTypes.removeFromCart,
                ecommerce: {
                    value: this.getMoneyFromItem(item),
                    items: [this.mapLineItemToEventItem(item, index)],
                },
            });
        } catch (e) {
            this.logError("AnalyticsClient.removeFromCart error", e);
        }
    }

    viewCart = async (items) => {
        if (!items) {
            return;
        }

        try {
            this.saveEcommerceEvent({
                event: EventTypes.viewCart,
                ecommerce: {
                    value: this.getCartValue(items),
                    event_id: await sha256(
                        EventTypes.viewCart + Math.random + Date.now().toString()
                    ),
                    items: items.map(this.mapLineItemToEventItem),
                },
            });
        } catch (e) {
            this.logError("AnalyticsClient.viewCart error", e);
        }
    };

    beginCheckout = async (items) => {
        if (!items) {
            return;
        }

        try {
            const mappedItems = items.map(this.mapLineItemToEventItem);
            this.saveEcommerceEvent({
                event: EventTypes.beginCheckout,
                ecommerce: {
                    value: this.getCartValue(items),
                    event_id: await sha256(
                        EventTypes.beginCheckout + Math.random + Date.now().toString()
                    ),
                    coupon: this?.me?.cartInfo?.cart?.discountCodes
                        .map((it) => it.discountCode.code)
                        .join(", "),
                    items: mappedItems,
                },
            });
        } catch (e) {
            this.logError("AnalyticsClient.beginCheckout error", e);
        }
    };

    addShippingInfo(items: LineItem[], shippingMethod?: string) {
        if (!items) {
            return;
        }

        try {
            this.saveEcommerceEvent({
                event: EventTypes.addShippingInfo,
                ecommerce: {
                    value: this.getCartValue(items),
                    shipping_tier: shippingMethod ?? undefined,
                    coupon: this?.me?.cartInfo?.cart?.discountCodes
                        .map((it) => it.discountCode.code)
                        .join(", "),
                    items: items.map(this.mapLineItemToEventItem),
                },
            });
        } catch (e) {
            this.logError("AnalyticsClient.addShippingInfo error", e);
        }
    }

    addPaymentInfo(items: LineItem[], paymentType) {
        if (!items) {
            return;
        }

        try {
            this.saveEcommerceEvent({
                event: EventTypes.addPaymentInfo,
                ecommerce: {
                    value: this.getCartValue(items),
                    payment_type: paymentType ?? undefined,
                    coupon: this?.me?.cartInfo?.cart?.discountCodes
                        .map((it) => it.discountCode.code)
                        .join(", "),
                    items: items.map(this.mapLineItemToEventItem),
                },
            });
        } catch (e) {
            this.logError("AnalyticsClient.addPaymentInfo error", e);
        }
    }

    async purchaseAdded(order: Order, algoliaQueryId) {
        try {
            this.saveEcommerceEvent({
                event: EventTypes.purchase,
                ecommerce: {
                    value: this.getCartValue(order.lineItems),
                    transaction_id: order?.orderNumber ?? undefined,
                    affiliation: "Online",
                    purchase_email: order.shippingAddress.email,
                    purchase_email_sha: await sha256(order.shippingAddress.email),
                    purchase_payment: order?.paymentInfo?.payments?.[0]?.paymentMethodInfo?.method,
                    purchase_store_id: order.store.key,
                    purchase_store_name: order.store.key,
                    shipping: order.shippingInfo.shippingMethodName,
                    tax: getMoneyFromCentsAndFractions(
                        order.taxedPrice.totalTax.centAmount,
                        order.taxedPrice.totalTax.fractionDigits
                    ),
                    event_id: await sha256(EventTypes.purchase + order?.orderNumber),
                    items: order.lineItems.map(this.mapLineItemToEventItem),
                },
            });

            aa(
                algoliaQueryId
                    ? AlgoliaEventMethods.purchasedObjectIDsAfterSearch
                    : AlgoliaEventMethods.purchasedObjectIDs,
                {
                    index: this.algoliaIndexName,
                    objectIDs: order.lineItems.map((it) => it.variant.sku),
                    eventName: algoliaQueryId
                        ? AlgoliaEventMethods.purchasedObjectIDsAfterSearch
                        : AlgoliaEventMethods.purchasedObjectIDs,
                    value: this.getCartValue(order.lineItems),
                    currency: gottenConfig.currency,
                    queryID: algoliaQueryId,
                }
            );
        } catch (e) {
            this.logError("AnalyticsClient.purchaseAdded error", e);
        }
    }

    filtersView(filters) {
        if (!filters.length) {
            return;
        }
        try {
            const appliedFilters = filters.reduce((acc, item) => {
                item.refinements.forEach((it) => acc.push(`${item.label}: ${it.label}`));
                return acc;
            }, []);

            aa(AlgoliaEventMethods.viewedFilters, {
                filters: appliedFilters,
                index: this.algoliaIndexName,
                eventName: AlgoliaEventMethods.viewedFilters,
            });
        } catch (e) {
            this.logError("AnalyticsClient.filterView error", e);
        }
    }

    listFilterApplied = (filterName, filterCategory, facetName) => {
        try {
            this.saveEvent({
                event: EventTypes.listFilterApplied,
                filter_category: filterCategory,
                filter_name: filterName,
            });

            aa(AlgoliaEventMethods.clickedFilters, {
                filters: [`${facetName}:${filterCategory}`],
                index: this.algoliaIndexName,
                eventName: AlgoliaEventMethods.clickedFilters,
            });
            this.lastAppliedFilter = { filterName, filterCategory, facetName };
        } catch (e) {
            this.logError("AnalyticsClient.listFilterApplied error", e);
        }
    };

    listSortingApplied = (sorting: string) => {
        try {
            this.saveEvent({
                event: EventTypes.listSortingApplied,
                sorting_applied: sorting,
            });
            this.lastAppliedSorting = { sorting };
        } catch (e) {
            this.logError("AnalyticsClient.listSortingApplied error", e);
        }
    };

    newsletterSubscribe = async (userEmail: string) => {
        try {
            this.saveEvent({
                event: EventTypes.newsletterSubscribe,
                nl_email: userEmail,
                nl_email_sha256: await sha256(userEmail),
                nl_first_name: undefined,
                nl_last_name: undefined,
            });
        } catch (e) {
            this.logError("AnalyticsClient.newsletterSubscribe error", e);
        }
    };

    purchaseStatus = (result, paymentMethod: string) => {
        try {
            this.saveEvent({
                event: EventTypes.purchaseStatus,
                status_order_id: result.orderId,
                status_pay_method: paymentMethod,
                status_category: this.resultCodeToPaymentState(result?.resultCode),
                status_response_code: result?.resultCode,
                status_reason: result.action,
            });
        } catch (e) {
            this.logError("AnalyticsClient.purchaseStatus error", e);
        }
    };

    accountCreate = () => {
        try {
            this.saveEvent({
                event: EventTypes.accountCreate,
            });
        } catch (e) {
            this.logError("AnalyticsClient.accountCreate error", e);
        }
    };

    cartContents = () => {
        if (!this.me?.cartInfo?.cart) {
            return;
        }
        this.saveEvent(
            {
                event: EventTypes.cartContents,
                basket_id: this.me?.cartInfo?.cart?.id,
                basket_url: window.location.hostname + "/shopping-bag",
                cart_size: this.me?.cartInfo?.cart?.lineItems.reduce((acc, it) => {
                    return it.quantity + acc;
                }, 0),
                currency: getCurrencyForSite,
                value: this.getCartValue(this.me?.cartInfo?.cart?.lineItems),
                items: this.me?.cartInfo?.cart?.lineItems.map(this.mapLineItemToEventItem),
            },
            false
        );
    };

    viewPromotion = ({
        image,
        title,
        index,
        id,
    }: {
        image: string;
        title: string;
        index: number;
        id: string;
    }) => {
        const url = image.startsWith("http") ? image : `https:${image}`;
        this.saveEcommerceEvent({
            event: EventTypes.viewPromotion,
            ecommerce: {
                promotion_id: id,
                promotion_name: title?.trim(),
                creative_slot: index,
                creative_name: url,
            },
        });
    };

    selectPromotion = ({
        image,
        title,
        index,
        id,
    }: {
        image: string;
        title: string;
        index: number;
        id: string;
    }) => {
        this.lastSelectedPromotion = {
            promotionId: id,
            promotionName: title.trim(),
        };

        this.saveEcommerceEvent({
            event: EventTypes.selectPromotion,
            ecommerce: {
                promotion_id: id,
                promotion_name: title.trim(),
                creative_slot: index,
                creative_name: `https:${image}`,
            },
        });
    };

    saveUserData = async (
        user: Me,
        session: Session,
        isAuthenticated: boolean,
        pageType: string
    ) => {
        try {
            const customerId = user?.customer?.id ?? user?.cartInfo?.cart?.anonymousId ?? "";

            const savedSuperUserId = JSON.parse(localStorage.getItem("superUserId"));

            const superId = await sha256(
                (customerId ?? "") + window.navigator.userAgent.toString()
            );

            const customerAddress =
                user?.customer?.defaultShippingAddress ??
                user?.customer?.shippingAddresses?.[0] ??
                user?.cartInfo?.cart?.shippingAddress;
            const userDataEvent = {
                event: EventTypes.userData,
                user_id: isAuthenticated ? user?.customer?.id : session?.anonymousId,
                user_language: getLanguageFromLocale(this.locale),
                super_user_id: savedSuperUserId ?? superId,
                custom_session_id: session?.accessToken,
                content_group: pageType ?? "generic",
                currency: this.currencyCode,
                visitor_gender: undefined,
                visitor_email: customerAddress?.email ?? undefined,
                visitor_phone: customerAddress?.phone ?? undefined,
                visitor_first_name: isAuthenticated ? user?.customer?.firstName : undefined,
                visitor_last_name: isAuthenticated ? user?.customer?.lastName : undefined,
                visitor_email_sha256: customerAddress?.email
                    ? await sha256(customerAddress?.email)
                    : undefined,
                visitor_postal_code: customerAddress?.postalCode,
                visitor_status: isAuthenticated ? "logged-in" : "guest",
                store_purchase: undefined,
                last_purchase: undefined,
                first_purchase: undefined,
                delivery_rate: undefined,
                wishlist_products: user.wishlistInfo?.wishlist?.lineItems?.length ?? 0,
                wishlist_value: user.wishlistInfo?.wishlist?.lineItems?.reduce((acc, it) => {
                    return (
                        acc +
                        getMoneyFromCentsAndFractions(
                            it?.variant?.price?.value?.centAmount,
                            it?.variant?.price?.value?.fractionDigits
                        )
                    );
                }, 0),
                visitor_type: user.customer?.createdAt ? "New User" : undefined,
            };

            user?.customer && sessionStorage.setItem("savedUser", JSON.stringify(user));
            !savedSuperUserId && localStorage.setItem("superUserId", JSON.stringify(superId));
            this.saveEcommerceEvent(userDataEvent);
        } catch (e) {
            this.logError("AnalyticsClient.saveUserData error", e);
        }
    };

    resultCodeToPaymentState = (resultCode: string) => {
        switch (resultCode) {
            case "Authorised":
                return PaymentState.Paid;
            case "Pending":
            case "Received":
            case "IdentifyShopper":
            case "ChallengeShopper":
                return PaymentState.Pending;
            case "Refused":
            case "Error":
                return PaymentState.Failed;
            default:
                return PaymentState.Failed;
        }
    };

    private logError(message: string, error: Error) {
        Logger.warn(ServiceType.WEB, message, {
            tag: LogTag.ANALYTICS,
            error,
        });
    }

    private static _instance: AnalyticsClient;

    public static getInstance(): AnalyticsClient {
        if (!this._instance) {
            this._instance = new AnalyticsClient();
        }
        return this._instance;
    }
}

const analyticsClient = AnalyticsClient.getInstance();

export { analyticsClient as AnalyticsClient };
