import { useEffect } from "react";
import { useRouter } from "next/router";
import { useStore } from "@store/index";
import type { StoreState } from "../index";

export const useCaptureUtmParams = () => {
    const router = useRouter();
    const { setUtmParams } = useStore<StoreState>();

    useEffect(() => {
        const query = router.query;

        if (
            query.utm_source ||
            query.utm_medium ||
            query.utm_campaign ||
            query.utm_term ||
            query.utm_content
        ) {
            setUtmParams({
                campaign: query.utm_campaign as string,
                source: query.utm_source as string,
                medium: query.utm_medium as string,
                term: query.utm_term as string,
                content: query.utm_content as string,
            });
        }
    }, [router.query]);
};
