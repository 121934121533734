import { shoeConventionForSite } from "@config/site/site-config";
import { SizeLocales } from "@lib/constants/filtersConstants";
import type { StoreDataType } from "./types";

export const initialState: StoreDataType = {
    isLocked: false,
    hasFreshData: false,
    me: {
        customer: null,
        globalShoeSizeConvention: shoeConventionForSite?.[0] ?? SizeLocales.EU,
        cartInfo: {
            cart: null,
            masterProducts: null,
            shippingMethods: null,
            isExecuted: true,
        },
        wishlistInfo: { wishlist: null, masterProducts: null, isExecuted: true },
    },
    tracking: {
        utm: {
            source: "",
            medium: "",
            campaign: "",
            term: "",
            content: "",
        },
    },
    payment: null,
    draftPromoCode: null,
    products: {
        list: [],
        currentFilters: [],
        resultsCount: null,
        initialFilters: {},
        lastVisitedPlp: null,
        algoliaQueryID: null,
        lastLoadedPage: 1,
        lastVisitedPlpContainerHeight: 0,
    },
};
