export enum FilterTypes {
    common = "common",
    size = "size",
    color = "color",
    price = "price",
}

export enum HierarchicalCategories {
    lvl0 = "hierarchicalCategories.lvl0",
    lvl1 = "hierarchicalCategories.lvl1",
    lvl2 = "hierarchicalCategories.lvl2",
}

export enum SizeLocales {
    EU = "EU",
    US = "US",
    UK = "UK",
    CA = "CA",
    KR = "KR",
    JP = "JP",
    CN = "CN",
}

export const MAX_SHOE_SIZE = 50;
export const MIN_SHOE_SIZE = 19;
export const ONE_SIZE = "one";
export const INITIAL_RESULTS_NUMBER = 80;
export const HIERARCHICAL_CATEGORIES_SEPARATOR = ">";
export const AND_SYMBOL = "_AND_";
