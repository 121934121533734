import type { Customer } from "@graphql/generated/components";
import { useRouter } from "next/router";
import { useEffect } from "react";
import FetchService from "@lib/helpers/fetchService";
import { datadogLogs } from "@datadog/browser-logs";
import { useAuth } from "@lib/auth/useAuth";
import { useStore } from "../store";
// Rest API Methods
export const useGetMyAddresses = () => {
    const { basePath } = useRouter();

    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;

    const fetchAddresses = async () => {
        try {
            const addressesData = await FetchService.get(`${basePath}/api/addresses/get-addresses`);
            if (addressesData) setData(addressesData as Customer);
            return addressesData;
        } catch (error) {
            datadogLogs.logger["error"](`ADDRESSES: Issue getting addresses`, {
                tag: "ADDRESSES",
                error: error,
            });
            return error;
        }
    };

    useEffect(() => {
        fetchAddresses();
        return () => {};
    }, [setData]);

    return { fetchAddresses };
};

export const useGetMyProfile = () => {
    const { basePath } = useRouter();
    const { isAuthenticated } = useAuth();
    const setData = useStore((state) => state.setMyProfile) as (customer: Customer) => void;
    const fetchProfile = async () => {
        try {
            const profileData = await FetchService.get(`${basePath}/api/user/get-user`);
            setData(profileData as Customer);
            return profileData;
        } catch (error) {
            datadogLogs.logger["error"](`PROFILE: Issue getting profile data`, {
                tag: "PROFILE",
                error: error,
            });
            return error;
        }
    };
    useEffect(() => {
        if (isAuthenticated) {
            fetchProfile();
        }
        return () => {};
    }, [setData]);

    return { fetchProfile };
};

export const useAddAddressToCustomer = () => {
    const { basePath } = useRouter();
    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;
    const addAddressToCustomer = async (address) => {
        try {
            const addedAddressData = await FetchService.post(
                `${basePath}/api/addresses/add-address`,
                address
            );

            if (addedAddressData) {
                setData(addedAddressData as Customer);
                return { addedAddressData };
            }
        } catch (error) {
            datadogLogs.logger["error"](`ADDRESSES: Issue adding addresses`, {
                tag: "ADDRESSES",
                error: error,
            });
            return error;
        }
    };
    return { addAddressToCustomer };
};

export const useUpdateAddressToCustomer = () => {
    const { basePath } = useRouter();
    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;
    const updateAddressToCustomer = async (address) => {
        try {
            const updatedAddressData = await FetchService.put(
                `${basePath}/api/addresses/update-address`,
                address
            );

            if (updatedAddressData) {
                setData(updatedAddressData as Customer);
                return { updatedAddressData };
            }
        } catch (error) {
            datadogLogs.logger["error"](`ADDRESSES: Issue updating addresses`, {
                tag: "ADDRESSES",
                error: error,
            });
            return error;
        }
    };
    return { updateAddressToCustomer };
};

export const useRemoveAddressToCustomer = () => {
    const { basePath } = useRouter();
    const state: any = useStore();
    const setData = state.setMyAddresses;
    const removeAddressToCustomer = async (address) => {
        try {
            const data = await FetchService.delete(
                `${basePath}/api/addresses/delete-address`,
                address
            );

            if (data) {
                setData(data as Customer);
                return { data };
            }
        } catch (error) {
            datadogLogs.logger["error"](`ADDRESSES: Issue removing addresses`, {
                tag: "ADDRESSES",
                error: error,
            });
            return error;
        }
    };
    return { removeAddressToCustomer };
};

export const useUpdateProfile = () => {
    const { basePath } = useRouter();
    const setData = useStore((state) => state.setMyProfile) as (customer: Customer) => void;
    const updateProfile = async (profile) => {
        try {
            const updatedProfileData = await FetchService.put(
                `${basePath}/api/user/update-user`,
                profile
            );

            if (updatedProfileData) {
                setData(updatedProfileData as Customer);
                return { updatedProfileData };
            }
        } catch (error) {
            datadogLogs.logger["error"](`PROFILE: Issue Updating Profile`, {
                tag: "PROFILE",
                error: error,
            });
            return error;
        }
    };
    return { updateProfile };
};
