import { Logger, LogTag, ServiceType } from "./../monitoring/logger";

export class FetchService {
    public async get<T>(url: string, options?: RequestInit) {
        const requestOptions = {
            method: "GET",
            ...options,
        };

        const startTime = performance.now();
        const response = await this.customFetch(url, requestOptions);
        const result = this.handleResponse<T>(response);

        Logger.info(
            this.isRequestFromBrowser ? ServiceType.WEB : ServiceType.NEXT_API,
            "FetchService: GET Request",
            {
                tag: LogTag.PERFORMANCE,
                duration: performance.now() - startTime,
            }
        );

        return result;
    }

    /**
     *
     * @param url URL to make POST request
     * @param body Body to send in POST request
     * @param options
     */
    public async post<T, R>(url: string, body: T, options?: RequestInit) {
        const { headers, ...extraOpts } = options || {};
        const isBodyObject = typeof body === "object" && !(body instanceof URLSearchParams);
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": isBodyObject ? "application/json" : "",
                ...headers,
            },
            body: body as any,
            ...extraOpts,
        };

        if (body) {
            requestOptions.body = isBodyObject ? JSON.stringify(body) : body;
        }

        const startTime = performance.now();
        const response = await this.customFetch(url, requestOptions);
        const result = this.handleResponse<R>(response);

        Logger.info(
            this.isRequestFromBrowser ? ServiceType.WEB : ServiceType.NEXT_API,
            "FetchService: POST Request",
            {
                tag: LogTag.PERFORMANCE,
                duration: performance.now() - startTime,
            }
        );

        return result;
    }

    public async delete<T>(url: string, body: any, options?: RequestInit) {
        const { headers, ...extraOpts } = options || {};
        const isBodyObject = typeof body === "object" && !(body instanceof URLSearchParams);
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": isBodyObject ? "application/json" : "",
                ...headers,
            },
            body: body,
            ...extraOpts,
        };

        if (body) {
            requestOptions.body = isBodyObject ? JSON.stringify(body) : body;
        }
        const response = await this.customFetch(url, requestOptions);
        return this.handleResponse<T>(response);
    }

    public async put<T>(url: string, body: any, options?: RequestInit) {
        const { headers, ...extraOpts } = options || {};
        const isBodyObject = typeof body === "object" && !(body instanceof URLSearchParams);
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": isBodyObject ? "application/json" : "",
                ...headers,
            },
            body: body,
            ...extraOpts,
        };

        if (body) {
            requestOptions.body = isBodyObject ? JSON.stringify(body) : body;
        }
        const response = await this.customFetch(url, requestOptions);
        return this.handleResponse<T>(response);
    }

    private async handleResponse<T>(response: Response): Promise<T> {
        const { ok, statusText } = response;

        const result = await response.json().catch(() => {
            if (ok) {
                return { ok, statusText, body: {} };
            }
        });

        if (!ok) {
            const error = (result && result.error) || (result && result.errors) || statusText;
            return Promise.reject({ error, status: response.status ?? 500 });
        }

        return result.data ?? result;
    }

    /**
     * NOTE: Use browser fetch if request is coming from browser else use fetchWithLogging which is
     * an implementation of isomorphic fetch for server side. It is important to note that browser
     * fetch should be used for the requests coming from browser so that RUM SDK can monkey patch
     * the fetch function with traceIDs. These tracesIDs are then sent as part of the request headers
     * to the fetchWithLogging function which then logs the request headers from there, the netlify log
     * drain picks up the logs and sends them to datadog.
     */

    private async customFetch(url: string, options?: RequestInit): Promise<Response> {
        const defaultLocale = process.env.NEXT_LOCALES?.split(",")[0];

        const optionsWithLocale = {
            ...options,
            headers: {
                ...options?.headers,
                "X-Ecco-Locale": defaultLocale,
            },
        };

        if (this.isRequestFromBrowser) {
            return await window.fetch(url, optionsWithLocale);
        }
        const { default: fetchWithLogging } = await import("./fetchWithLogging");
        return await fetchWithLogging(url, optionsWithLocale);
    }

    private isRequestFromBrowser = typeof window !== "undefined";
}

export default new FetchService();
